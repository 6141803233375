import React from 'react'
import Cases from '../../components/Cases/Cases'
import fourthOptions from '../../assets/fourthSection.json';

function Fourth() {
	const { sections } = fourthOptions;
	return (
		<div className={'forthSection'} >
			{sections.map((section, index) => <Cases key={`casesComponent${index}`} id={section.id} title={section.title} items={section.items} />)}
		</div>
	)
}

export default Fourth
