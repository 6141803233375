import React, { useEffect } from 'react';
import useScrollPosition from '../../hooks/useScrollPosition';
import "./GoToTop.scss";

function GoToTop() {
	const scrollPosition = useScrollPosition();

	const goTo = (id: string) => {
		const element = document.getElementById(id);
		element?.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<div className={`goToTopComponent ${scrollPosition > 200 ? 'show' : ''}`} onClick={() => goTo("home")} ><i className="fas fa-chevron-up"></i></div>
	)
}

export default GoToTop
