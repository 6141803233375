import React from 'react';
import firstSectionImage from '../../assets/img/sections/firstSection/firstSection.jpg';
import firstSection from '../../assets/firstSection.json';
import './First.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function First() {
	const style = {
		backgroundImage: `url(${firstSectionImage})`
	}
	const { texts }: { texts: string[] } = firstSection;
	const { width } = useWindowDimensions();
	return (
		<div id="home" className={'firstSection'}>
			{/* <div className={'firstSection-image'} >
				<img src={firstSectionImage} alt="" />
			</div> */}
			<div className={'firstSection-first'} style={style} >
				<div className={'firstSection-first-content'} >
					<p> A MELHOR EM <br />TECNOLOGIA <br />& EFICIÊNCIA </p>
					<p>Sistemas de segurança, consultoria, <br />projeto, locação e venda.</p>
				</div>
			</div>
			<div id="whatwedo" className={'firstSection-second'}>
				<div className={'firstSection-second-main'} >
					<div className={'firstSection-second-main-title'} >
						<div></div>
						<div>
							<p>O QUE FAZEMOS</p>
						</div>
					</div>

					<div className={'firstSection-second-main-body'}>
						{
							width < 960
								? <div>Consultoria, treinamento, suporte e manutenção.</div>
								: <div>Consultoria, <br />treinamento, <br />suporte e <br />manutenção.</div>
						}
						<div>
							{texts.map((text, index) => <p key={`firstSectionText${index}`} >{text}</p>)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default First
