import React from 'react'
import thirdSection from '../../assets/thirdSection.json';
import './Third.scss';

function Third() {
	const { texts, embedId }: { texts: string[], embedId: string } = thirdSection;
	return (
		<div id="aboutus" className={'thirdSection'} >
			<div className={'thirdSection-leftSide'} >
				<div className={'thirdSection-leftSide-title'} >
					<div></div>
					<p>QUEM SOMOS</p>
				</div>
				<div className={'thirdSection-leftSide-content'} >
					{texts.map((text, index) => <p key={`thirdSectionText${index}`} >{text}</p>)}
				</div>
			</div>
			<div className={'thirdSection-rightSide'}>
				<iframe
					id="iframeVideo"
					width="100%"
					height="100%"
					src={`https://www.youtube.com/embed/${embedId}`}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title="Embedded youtube"
				/>
				{/* <label htmlFor={"iframeVideo"} className={'thirdSection-rightSide-label'} >
					<p>Veja o Video</p>
					<i className="far fa-play-circle"></i>
				</label> */}
			</div>
		</div>
	)
}

export default Third
