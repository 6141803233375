import React from 'react';
import fifthSection from '../../assets/fifthSection.json';
import "./Fifth.scss";

function Fifth() {
	const { services, googleMapKey } = fifthSection;
	return (
		<div id="contact" className={'fifthSection'} >
			<div className={'fifthSection-floater'} >
				<div className={'fifthSection-floater-title'} >
					<div></div>
					<p>FALE COM A GENTE</p>
				</div>
				<div className={'fifthSection-floater-address'} >
					{services.address.map((info, index) => <p key={`address${index}`} >{info}</p>)}
				</div>
				<div className={'fifthSection-floater-email'} > <p>{services.email}</p> </div>
				<div className={'fifthSection-floater-cellphone'} > <p>{services.cellphone}</p> </div>
			</div>
			<div className={'fifthSection-map'} >
				<iframe
					width="100%"
					height="100%"
					loading="lazy"
					allowFullScreen
					src={`https://www.google.com/maps/embed/v1/place?q=Av.%20Bar%C3%A3o%20Homem%20de%20Melo%2C%204386%20-%20Sala%201008%20-%20Estoril%2C%20Belo%20Horizonte%20-%20MG%2C%2030494-270&key=${googleMapKey}`} />
			</div>
		</div >
	)
}

export default Fifth
