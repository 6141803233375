export const importImages = async (images: string | string[]) => {
	try {
		if (!Array.isArray(images)) return (await require(`../assets/${images}`).default);
		let imagesPack = [];
		for (const imageKey in images) {
			const image = await require(`../assets/${images[imageKey]}`);
			imagesPack.push(image.default);
		}
		return imagesPack;
	} catch (error) {
		if (error) return '';
	}
}