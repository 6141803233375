import { useState, useEffect } from 'react';

const getScrollPosition = () => {
	const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
	return winScroll;
}

const useScrollPosition = () => {
	const [scroll, setScroll] = useState(getScrollPosition());
	useEffect(() => {
		const handleScroll = () => setScroll(getScrollPosition());
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);
	return scroll;
}

export default useScrollPosition;