import React, { useEffect, useState } from 'react'
import footer from "../../assets/footer.json";
import { importImages } from '../../utils/utils';
import wrLogo from '../../assets/img/logos/wrLogo.png';
import "./Footer.scss";

interface ILogos {
	logo: string;
	link: string;
}

function Footer() {
	const { names, socialMedia } = footer;
	const [logos, setLogos] = useState<ILogos[]>();

	useEffect(() => {
		(async () => {
			const logoArr = [];
			for (const key in names) {
				logoArr.push({
					link: names[key].link,
					logo: await importImages(names[key].logo)
				});
			}
			setLogos(logoArr);
		})()
	}, []);

	const openTab = (link?: string) => {
		if (link) window.open(link, "_blank");
	}

	const goTo = (id: string) => {
		const element = document.getElementById(id);
		element?.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<div className={'footerComponent'} >
			<div className={'footerComponent-title'} >
				<div></div>
				<p>PARCEIROS</p>
			</div>
			<div className={'footerComponent-main'} >
				<div className={'footerComponent-main-leftSide'} >
					{
						logos ?
							logos.map(
								(logo, index) =>
									<img key={`partnes${index}`} onClick={() => openTab(logo.link)} src={logo.logo} alt="" />
							) : undefined
					}
				</div>
				<div className={'footerComponent-main-rightSide'} >
					<div> <img onClick={() => goTo("home")} src={wrLogo} alt="" /> </div>
					<div>
						{
							socialMedia.map(
								(item, index) =>
									<a
										key={`socialMediaLinks${index}`}
										rel="noreferrer"
										href={item.link}
										target="_blank">
										<i className={item.icon} ></i>
									</a>
							)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer;