import React, { useEffect, useState } from 'react';
import { importImages } from '../../utils/utils';
import "./Cases.scss";

interface ICases {
	id: string;
	title?: string;
	items?: {
		text?: string,
		link?: string,
		image?: string | string[],
	};
}

function Cases({ id, title, items }: ICases) {
	const [images, setImages] = useState<string | string[]>('');

	useEffect(() => {
		(async () => {
			if (items && items.image) setImages(await importImages(items.image));
		})()
	}, []);

	const openNews = (link?: string) => {
		if (link) window.open(link, "_blank");
	}

	return (
		<div id={id} className={'casesComponent'} >
			<div className={'casesComponent-title'} >
				<div> <p> {title} </p> </div>
				<div>
					<div></div>
					<p> {title?.toUpperCase()} </p>
				</div>
			</div>
			{
				items ? <div className={`casesComponent-body ${items.link ? 'link' : ''}`} onClick={() => openNews(items.link)} >
					{
						Array.isArray(images) ?
							<div className={`casesComponent-body-imagesWrapper`} >
								{images.map((image, index) => <img key={`caseComponentImage${index}`} src={image} alt="" />)}
							</div> :
							<img src={images} className={`${items.text ? 'brightness' : ''}`} alt="" />
					}
					{items.text ? <div className={'casesComponent-body-text'} dangerouslySetInnerHTML={{ __html: items.text }} ></div> : undefined}
				</div> : undefined
			}
		</div>
	)
}

export default Cases;