import React, { useState } from 'react';
import servicesTexts from '../../assets/secondSection.json';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './Second.scss';

interface ITextOptions {
	icon: string;
	phrase: string;
	id: string;
}

interface IServices {
	id: string,
	texts: {
		title: string,
		text: string
	}[];
}

function Second() {
	const [option, setOption] = useState<string>('cftv');
	const { services, options }: { services: IServices[], options: ITextOptions[] } = servicesTexts;
	const { width } = useWindowDimensions();
	return (
		<div className={'secondSection'}>
			<div className={'secondSection-main'}>
				<div className={'secondSection-main-leftSide'} >
					{
						options.map(
							(selectedOption, index) => <div key={`${selectedOption.id}${index}`} onClick={() => setOption(selectedOption.id)} >
								{
									width > 960
										? <i className={selectedOption.icon} ></i>
										: undefined
								}
								<p> {selectedOption.phrase} </p>
								{selectedOption.id === option ? <div></div> : undefined}
							</div>
						)
					}
				</div>
				<div className={'secondSection-main-rightSide'} >
					{
						services
							.filter(serviceOption => serviceOption.id === option)
							.map(
								(service, index) => <div key={`secondSectionMainRightSide${index}`}>
									{
										service.texts.map(
											(option, index) => <div key={`secondSectionMain${index}`} >
												<p> {option.title} </p>
												<p> {option.text} </p>
											</div>
										)
									}
								</div>
							)
					}
				</div>
			</div>
		</div>
	)
}

export default Second
