import React, { useState } from 'react';
import useScrollPosition from '../../hooks/useScrollPosition';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Hamburger from '../../components/HamburgerMenu/HamburgerMenu';
import wrLogo from '../../assets/img/logos/wrLogo.png';
import navbar from '../../assets/navbar.json';
import './Navbar.scss';

function Navbar() {
	const { width } = useWindowDimensions();
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const { links } = navbar;

	const goTo = (id: string) => {
		const element = document.getElementById(id);
		element?.scrollIntoView({ behavior: "smooth" });
	}

	return (
		// <div className={`navbarComponent ${scrollPosition > 50 ? 'scrolled' : ''}`}>
		<div className={`navbarComponent`}>
			<div className={'navbarComponent-main'} >
				<div className={'navbarComponent-main-content'} >
					<div className={'navbarComponent-main-content-logo'} >
						<img src={wrLogo} alt="" />
					</div>
					{
						width > 960 ?
							<div className={'navbarComponent-main-content-linkToDiv'} >
								{
									links.map(
										(link, index) =>
											<div onClick={() => goTo(link.id)} key={`linkToDivItem${index}`} className={'navbarComponent-main-content-linkToDiv-item'} >
												<div>{link.display}</div>
												{index < links.length - 1 ? <div className={'bar'}>|</div> : ''}
											</div>
									)
								}
							</div>
							: <div className={'navbarComponent-main-content-hamburger'} >
								<Hamburger open={openMenu} setOpen={setOpenMenu} />
							</div>
					}
				</div>
			</div>

			{
				width <= 960 ?
					<div className={`navbarComponent-mobile ${openMenu ? 'show' : ''}`} >
						<div className={'navbarComponent-mobile-closeDiv'} onClick={() => setOpenMenu(false)} ></div>
						<div className={`navbarComponent-mobile-links`} >
							<div className={`navbarComponent-mobile-links-wrapper`} >
								{
									links.map(
										(link, index) =>
											<div
												key={`linkToDivItemMobile${index}`}
												onClick={() => goTo(link.id)}
												className={`navbarComponent-mobile-links-wrapper-item`}>
												{link.display}
											</div>
									)
								}
							</div>
						</div>
					</div> : undefined
			}
		</div>
	)
}

export default Navbar
