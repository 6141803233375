import React from 'react';
import Footer from './sections/Footer/Footer';
import Navbar from './sections/Navbar/Navbar';
import Fifth from './sections/Fifth/Fifth';
import First from './sections/First/First';
import Fourth from './sections/Fourth/Fourth';
import Second from './sections/Second/Second';
import Third from './sections/Third/Third';
import "./App.scss";
import GoToTop from './components/GoToTop/GoToTop';

function App() {
  return (
    <div className="App">
      <GoToTop />
      <Navbar />
      <First />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
      <Footer />
    </div>
  );
}

export default App;
